
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditContents from "@/components/reusable/claims/house-insurance/contents/EditContents.vue";

export default defineComponent({
  name: "edit-contents-house-insurance-claims",
  components: { EditContents },
  data() {
    return {
      publicId: this.$route.params.publicId
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit Personal Effects", [
        "Claims",
        "Report Claims",
        "House Insurance Claim"
      ]);
    });
  }
});
