
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "house-building-policy-information-content-check-component",
  components: { Field },
  props: {
    title: String,
    image: String,
    firstHeader: String,
    secondHeader: String,
    items: Array,
    itemValue: Number,
    name: String
  },
  data() {
    return { itemChecker: false, activeItem: "disabled" };
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    activateItem(name) {
      const itemSelected = $("." + name);

      if (itemSelected.hasClass(this.activeItem)) {
        itemSelected.attr("disabled", false);
        itemSelected.removeClass(this.activeItem);
      } else {
        itemSelected.attr("disabled", true);
        itemSelected.addClass(this.activeItem);
      }
    }
  }
});
