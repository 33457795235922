import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1f2a1a5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check form-check-sm form-check-custom form-check-solid me-2" }
const _hoisted_2 = ["onChange"]
const _hoisted_3 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_4 = { class: "d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2" }
const _hoisted_5 = { class: "flex-grow-1 my-lg-0 my-2 me-2" }
const _hoisted_6 = { class: "text-gray-800 fw-bolder fs-6" }
const _hoisted_7 = { class: "text-gray-600 fw-bolder d-block pt-1" }
const _hoisted_8 = { class: "text-gray-600 fw-bolder d-block pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "mb-7 d-flex align-items-sm-center col-xl-6",
      key: index
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Field, {
          type: "checkbox",
          name: "itemChecker",
          modelValue: _ctx.itemChecker,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemChecker) = $event))
        }, {
          default: _withCtx(({ field }) => [
            _createElementVNode("input", _mergeProps({
              class: "form-check-input",
              type: "checkbox",
              ref_for: true
            }, field, {
              onChange: ($event: any) => (_ctx.activateItem(`${_ctx.name}-item-${index}`))
            }), null, 16, _hoisted_2)
          ]),
          _: 2
        }, 1032, ["modelValue"]),
        (item.serialNumber)
          ? (_openBlock(), _createBlock(_component_Field, {
              key: 0,
              class: _normalizeClass(`${_ctx.name}-item-${index} ${_ctx.activeItem}`),
              id: `${_ctx.name}-item-${index}`,
              type: "hidden",
              name: `${_ctx.name}[${index}][serialNumber]`,
              modelValue: item.serialNumber,
              "onUpdate:modelValue": ($event: any) => ((item.serialNumber) = $event),
              disabled: ""
            }, null, 8, ["class", "id", "name", "modelValue", "onUpdate:modelValue"]))
          : (_openBlock(), _createBlock(_component_Field, {
              key: 1,
              class: _normalizeClass(`${_ctx.name}-item-${index} ${_ctx.activeItem}`),
              id: `${_ctx.name}-item-${index}`,
              type: "hidden",
              name: `${_ctx.name}[${index}][brand]`,
              modelValue: item.brand,
              "onUpdate:modelValue": ($event: any) => ((item.brand) = $event),
              disabled: ""
            }, null, 8, ["class", "id", "name", "modelValue", "onUpdate:modelValue"])),
        _createVNode(_component_Field, {
          class: _normalizeClass(`${_ctx.name}-item-${index} ${_ctx.activeItem}`),
          id: `${_ctx.name}-item-${index}`,
          type: "hidden",
          name: `${_ctx.name}[${index}][value]`,
          modelValue: item.value,
          "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
          disabled: ""
        }, null, 8, ["class", "id", "name", "modelValue", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "symbol-label",
          style: _normalizeStyle(`background-image: url(${_ctx.image});`)
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.title ? _ctx.title : "Unavailable"), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.firstHeader ? _ctx.firstHeader : "Serial Number") + ": " + _toDisplayString(item.serialNumber != null
              ? item.serialNumber
              : item.brand != null
              ? item.brand
              : ""), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.secondHeader ? _ctx.secondHeader : "Value") + ": ₦" + _toDisplayString(item.value ? _ctx.addCommaToNumberString(item.value) : "0"), 1)
        ])
      ])
    ]))
  }), 128))
}